import PageCongNo from "../pages/DanhSachCongNo/PageCongNo";
import HoaDonsPage from "../pages/DanhSachHoaDonsKh/HoaDonsPage";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/LoginPage/LoginPage";

export const routes=[
    {
        path:'/',
        component:<HomePage/>,
    },
    {
        path:'/hoadons/:id',
        component:<HoaDonsPage/>,
    },
    {
        path:'/congNo',
        component:<PageCongNo/>,
    },
    {
        path:'/login',
        component:<LoginPage/>,
    }
]