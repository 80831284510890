import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import usehis from 'react-router-dom'
import { checkRes } from '../../../unity'
import Header from '../../components/Header'
import Secureview from '../../HOC/Secureview'
import khachHangSlice, { setKhachHang } from '../../redux/khachHangSlice'
import { api_2024 } from '../../services/apiService'
import ListHd from './components/ListHd'
import ListKhachHang from './components/ListKhachHang'
import Total from './components/Total'

function HomePage() {
  const dispatch=useDispatch();
  const [loading,setLoading]=useState(false);
 useEffect(()=>{
  setLoading(true);
   api_2024.get("khachHang",{include:[{as:"hoaDons",include:[{as:"chiTietHoaDons"}]},{as:"bienDongs"}]}).then(
    (res)=>{
       let value=checkRes(res);
       if(value){
          dispatch(setKhachHang(value));
          setLoading(false);
       }else{
        alert(res.err);
       }
    }
   )
 },[])
  return (
   <Secureview>
        <Spin spinning={loading}>
       <div className='mb-20 bg-slate-100'>
        <Header title={"Xin chào"}></Header>
        <Total></Total>
        <ListKhachHang></ListKhachHang>
        <ListHd></ListHd>
   </div> 
     </Spin>
   </Secureview>
  )
}

export default HomePage