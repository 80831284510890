import React, { useState, useEffect, useRef } from 'react';
import { checkRes, formatCurrency, removeVietnameseDiacritics } from '../../../unity';
import { api_2024 } from '../../services/apiService';

function ProductList({handleSelectProduct}) {
  const [products,setProducts] = useState([]);
  const [selectedProduct,setSelectedProduct] = useState([]);
  const [searchText, setSearchText] = useState(''); // Từ khóa tìm kiếm
  const [showList, setShowList] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    api_2024.get("sanPham")
      .then((res) => {
        const value = checkRes(res);
        if (value) {
          setProducts(value)
        }
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
      });
  }, []);

 

  const handleFilterChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    setShowList(true);
  };

  const handleListClick = () => {
    // Xử lý sự kiện khi danh sách sản phẩm được nhấn
  };

  const handleOutsideClick = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const filteredProducts = products
    .filter((product) => {
      const normalizedSearchText =removeVietnameseDiacritics(searchText.toLowerCase()) ;
      const normalizedTenSp = removeVietnameseDiacritics(product.tenSp.toLowerCase());

      return (
        normalizedTenSp.includes(normalizedSearchText) &&
        product.maSp
      );
    })
    .slice(0, 4);

  return (
    <>
     
    <div className="  rounded-lg shadow-lg  max-w-md space-y-4">
    <h2 className=" text-xl font-semibold ">Danh sách sản phẩm</h2>
     <div>
     <input
        type="text"
        className="w-full text-center border rounded-md text-lg py-1"
        placeholder="Tìm sản phẩm"
        value={searchText}
        onChange={handleFilterChange}
      />
     </div>
      {showList && (
        <div className=' p-3 overflow-scroll' ref={listRef} style={{ maxHeight: '300px', overflowY: 'auto' ,border:"1px solid black"}} onClick={handleListClick}>
          <ul className=' border p-1 rounded-md' style={{}} >
            {filteredProducts.map((product) => (
              <li
                key={product.maSp}
                className={`py-2 border cursor-pointer `}
                onClick={() => {handleSelectProduct(product);setShowList(false)}}
              >
                <div className="flex items-center justify-between">
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold">{product.tenSp}</h3>
                    <p className="text-gray-600 text-lg">Giá: {formatCurrency(product.giaSp)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      
      
    </div>
  </>
  )
}

export default ProductList;
